<template>
  <b-container class="main-content-container px-4 pb-4 mt-5" fluid>
    <b-row no-gutters class="text-center">
      <b-col>
        <b-form-group class="btnGroup" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="btnSelected"
            :options="btnOptions"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            name="radios-btn-default"
            v-on:change="filterData"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-card-group deck>
          <b-card
            :img-src="require('@/assets/images/logo.svg')"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2"
            v-for="(item, index) in arrayDataFilter" :key="index">
            <b-card-text>
              <h6>{{ item.name }}</h6>
              <p>{{ item.description }}</p>
            </b-card-text>
            <a :href="item.url" target="_blank" class="btn btn-primary">
              <i class="material-icons">download</i>
            </a>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {get} from "@/services/api";
import {generalFilesIndex} from "@/services/endpoint.json";

export default {
  name: "ListFileClient",
  data: () => ({
    urlImg: '@/assets/images/logo.svg',
    arrayData: [],
    arraySections: [],
    btnOptions: [],
    arrayDataFilter: [],
    btnSelected: null
  }),
  async mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      get('general-files/find-all', null, true)
        .then(({data}) => {
          const fi = data.files
          this.arrayData = fi.filter(x => Number(x.status) === 1)
          const options = data.sections;
          this.arraySections = options.filter(x => Number(x.status) === 1)
          this.checkSectionFile()
          if (this.arraySections.length) {
            this.btnSelected = this.arraySections[0].name
            this.filterData(this.arraySections[0].name)
          }
        }).catch();
    },
    filterData(section) {
      this.arrayDataFilter = this.arrayData.filter(x => (x.section.toString() === section.toString() || x.name.toString() === section.toString()))
    },
    checkSectionFile() {
      this.arraySections.forEach(item => {
        const count = this.arrayData.find(x => x.section == item.name)
        if (count !== undefined) {
          this.btnOptions.push(item.name);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
