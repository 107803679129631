<template>
  <div>
    <b-row>
      <b-col>
        <b-form-input v-model="name" placeholder="Section name"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-button variant="primary" type="submit" class="btn-block" @click="createSection"> SAVE INFORMATION</b-button>
      </b-col>
    </b-row>
    <b-tabs class="mt-3" fill>
      <b-tab title="ACTIVE SECTIONS" active class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
          <tbody>
          <tr>
            <td>Name</td>
            <td>Status</td>
            <td>Options</td>
          </tr>
          <tr v-for="(item, index) in activeSections">
            <td>{{ item.name }}</td>
            <td>{{ (item.status === 1) ? 'ACTIVE' : 'INACTIVE' }}</td>
            <td>
              <b-button v-if="Number(item.status) === 1"
                        variant="danger"
                        class="btn-primary"
                        title="Deactivate section"
                        @click="updateStatus(item._id)">
                <i class="material-icons">delete</i>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab title="INACTIVE SECTIONS" class="table-responsive">
        <b class="text-danger">Inactive sections and files would be automatically deleted after 30 days</b>
        <table class="table table-bordered table-hover table-sm mt-2">
          <tbody>
          <tr>
            <td>Name</td>
            <td>Status</td>
            <td>Options</td>
          </tr>
          <tr v-for="(item, index) in inactiveSections">
            <td>{{ item.name }}</td>
            <td>{{ (item.status === 1) ? 'ACTIVE' : 'INACTIVE' }}</td>
            <td>
              <b-button v-if="Number(item.status) === 0" class="" variant="primary"
                        title="Activate section"
                        @click="updateStatus(item._id)">
                <i class="material-icons">done</i>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {get, post} from "@/services/api";
import {generalFilesSectionIndex, generalFilesSectionUpdate, generalFilesSectionCreate} from "@/services/endpoint.json";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreateSection",
  components: {},
  mixins: [show_alert_mixin],
  data: () => ({
    arrayData: [],
    name: null,
  }),
  props: {
    activeInfo: {
      default: []
    },
    inactiveInfo: {
      default: []
    }
  },
  computed: {
    activeSections() {
      return this.activeInfo;
    },

    inactiveSections() {
      return this.inactiveInfo;
    },
  },
  watch: {
    activeSections: {
      immediate: true,
      handler(loc) {
      }
    },
    inactiveSections: {
      immediate: true,
      handler(loc) {
      }
    }
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async createSection() {
      if (this.name !== null && this.name !== '' && this.name.length <= 50) {
        const {data} = await post(generalFilesSectionCreate, {name: (this.name.trim()).toUpperCase()}, true)
        if (data === 200) {
          this.$emit('reloadInfo')
          this.name = ""
          this.showAlertNotification("Section created")
        } else {
          this.showAlertNotification("Name already exists", "error")
        }
      } else {
        this.showAlertNotification('All fields are required or section name is longer than 50 characters', 'error')
      }
    },

    async updateStatus(id) {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(generalFilesSectionUpdate, {id: id}, true)
          this.$emit('reloadInfo')
          this.showAlertNotification("Status updated")
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
