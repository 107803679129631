<template>
    <table v-else class="table table-bordered table-hover table-sm">
      <thead>
      <tr>
        <th>Name</th>
        <th>Date</th>
        <th style="width: 3vw">Roles</th>
        <th>Status</th>
        <th style="max-height: 15vw">Description</th>
        <th>Section</th>
        <th>TVS related</th>
        <th>Options</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in arrayData" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.created_at| localDateShort }}</td>
        <td style="width: 3vw">{{ item.rol.join(', ') }}</td>
        <td>{{ (item.status === 1) ? 'ACTIVE' : 'INACTIVE' }}</td>
        <td style="max-height: 15vw">{{ item.description }}</td>
        <td>{{ item.section }}</td>
        <td><span class="font-weight-bold" :class="item.tvs_related ? 'text-success':'text-danger'">{{ item.tvs_related?'YES':'NO' }}</span></td>
        <td>
          <b-button v-if="Number(item.status) === 1" class="btn-primary" variant="primary" @click="updateStatus(item._id)"
                    title="Deactivate file">
            <i class="material-icons">delete</i>
          </b-button>
          <b-button v-if="Number(item.status) === 0  " class="" variant="danger" @click="updateStatus(item._id)">
            <i class="material-icons">done</i>
          </b-button>
          <a :href="item.url" target="_blank" class="btn btn-primary" title="Download file">
            <i class="material-icons">download</i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>
import {post} from "@/services/api";

export default {
  name: "ListFile",
  props: {
    arrayData: {
      default: []
    },
  },
  methods: {
    async updateStatus(id) {
      this.$swal({
        title: 'Are you sure you want to delete this file?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post('general-files/update', {id: id}, true)
          this.$emit("reloadInfo")
          await this.showAlert('File deleted', 'success')
        }
      });
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
      });
    },
  }
}
</script>

<style scoped>

</style>
