<template>
  <form class="uploadexcelfile__form" enctype="multipart/form-data" @submit.prevent="onSubmit">
    <b-card-text>
      <b-row>
        <b-col>
          <input id="myFile" ref="file" class="uploadexcelfile__form-inputfile" type="file" @change="onSelect($event)" @click="resetInputFile($event)">
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <label for="">Select Section</label>
          <b-form-select v-model="sectionSelected" class="mb-3">
            <b-form-select-option :value="item.name" v-for="(item, index) in dataSections" :key="index">
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-form-checkbox v-if="$userCan(['administrator'])" name="radio-related" v-model="tvs_related" @change="filterRoles">TVS RELATED </b-form-checkbox>
          <hr>
          <b-form-group label="Select user roles" id="input-group-4" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkboxes-4"
              :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox @change="addOption($event, item)" :aria-describedby="ariaDescribedby"
                               name="some-radios" :value="item" v-for="(item, index) in roles" :key="index">
                {{ item.toUpperCase() }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-form-textarea v-model="description" placeholder="Enter Description" rows="5"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card-text>
    <b-button :disabled="!file" variant="danger" type="submit" class="btn-block"> SAVE INFORMATION</b-button>
  </form>
</template>

<script>
import {UserRole} from "@/utils/auth.roles";
import {mapMutations} from "vuex";
import {post} from "@/services/api";

export default {
  name: "UploadFile",
  components: {},
  props: {
    roles: {
      default: []
    },
    sectionInfo: {
      default: null
    },
    totalFiles: {
      default: true
    }
  },
  computed: {
    dataSections() {
      return this.sectionInfo;
    },
  },
  watch: {
    dataSections: {
      immediate: true,
      handler(loc) {
        this.dataSections = loc
      }
    }
  },
  data: () => ({
    arrayData: [],
    file: '',
    roles: [],
    rolesSelected: [],
    description: null,
    sectionSelected: null,
    tvs_related: true
  }),

  methods: {
    ...mapMutations(['loaderManager']),
    resetInputFile(e) {
      e.target.value = '';
    },
    onSelect(e) {
      this.file = e.target.files.item(0);
    },
    onSubmit() {
      this.loaderManager(true);
      if (this.totalFiles) {
        if (this.rolesSelected.length && this.description !== null && this.description !== '' && this.sectionSelected !== null && this.sectionSelected !== '') {
          if (this.description.length <= 500) {
            if (this.file.size <= (1024 * 1024 * 50)) {
              const formData = new FormData();
              formData.append('file', this.file);
              formData.append('tvs_related', this.tvs_related);
              this.rolesSelected.forEach(el => {
                formData.append('roles[]', el)
              })
              formData.append('description', this.description);
              formData.append('section', this.sectionSelected);
              post('general-files/create', formData, true)
                .then(({data}) => {
                  this.$emit('reloadInfo')
                  location.reload();
                }).catch((error) => {
                this.loaderManager(false);
              });
            } else {
              this.loaderManager(false);
              this.showAlert('Maximum file size is 50MB', 'error').then()
            }
          } else {
            this.loaderManager(false);
            this.showAlert('Maximum length is 500 characters', 'error').then()
          }
        } else {
          this.loaderManager(false);
          this.showAlert('All options are required', 'error').then()
        }
      } else {
        this.loaderManager(false);
        this.showAlert('It is only possible to upload 10 files', 'error').then()
      }
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
      });
    },
    addOption(e, item) {
      if (this.rolesSelected.length === 0) {
        this.rolesSelected.push(item)
      } else {
        const exist = this.rolesSelected.find(x=>x === item)
        if(exist){
          let i = this.rolesSelected.indexOf(item);
          this.rolesSelected.splice(i, 1);
        }else{
          this.rolesSelected.push(item)
        }
      }
    },
    clearData() {
      this.arrayData = [];
      this.file = '';
      this.rolesSelected = [];
      this.description = null;
      this.sectionSelected = null;
      this.roles = JSON.parse(JSON.stringify(Object.values(UserRole)));
    },
    filterRoles(){
      const roles = JSON.parse(JSON.stringify(Object.values(UserRole)));
      if(this.tvs_related){
          this.roles = roles.filter(x => x !== "administrator")
      }else{
        this.roles = ["dealer", "subdealer"]
      }
    }
  }
}
</script>

<style scoped>

</style>
