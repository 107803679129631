import Vue from 'vue';
import UploadFiles from "./UploadFile.vue";
import CreateSection from "./CreateSection.vue";
import ListFileClient from "./ListFileClient.vue";
import {get} from "@/services/api";
import {generalFilesIndex} from "@/services/endpoint.json";
import ListFiles from './ListFile.vue'

export default Vue.extend({
  name: 'GeneralFiles',
  components: {
    UploadFiles,
    CreateSection,
    ListFileClient,
    ListFiles
  },
  data: () => ({
    arraySections: [],
    arraySectionActive: [],
    arraySectionInactive: [],
    roles: [],
    arrayData: [],
    arrayDataDistributors: [],
    arrayNamesFilter: [{value: null, text: 'select distributor/dealer'}],
    filterValue: null,
    showUpload: false,
    showDownload: false,
    showOverlay: false,
    totalFiles: true
  }),
  created() {
    this.loadData()
  },
  mounted() {
    this.getTotalFiles().then()
    this.validateNavbar()
    if (this.$userCan(['administrator'])) {
      this.roles = ['distributor', 'dealer', 'subdealer', 'calibrator', 'support',]
    } else {
      this.roles = ['dealer', 'subdealer']
    }
  },
  methods: {
    async loadData() {
      return new Promise(resolve => {
        this.showOverlay = true
        get(generalFilesIndex, null, true)
          .then(({data}) => {
            this.arrayDataDistributors = []
            this.arrayData = data.files.filter(x => x.userId === null)
            this.arrayDataDistributors = data.files.filter(x => x.userId !== null)
            this.arraySections = data.sections
            this.arraySectionActive = data.sections.filter(x => Number(x.status) === 1)
            this.arraySectionInactive = data.sections.filter(x => Number(x.status) === 0)
            const tempNames = this.arrayDataDistributors.map(x => ({value: x.userId, text: x.fullName}))
            tempNames.forEach(item => {
              if (this.arrayNamesFilter.length === 0) {
                this.arrayNamesFilter.push(item)
              } else {
                const previous = this.arrayNamesFilter.find(x => x.value == item.value)
                if (previous == undefined) {
                  this.arrayNamesFilter.push(item)
                }
              }
            })
            this.showOverlay = false
            resolve(true)
          }).catch(() => {
          resolve(true)
        });
      })
    },
    async filterFiles() {
      await this.loadData()
      if (this.filterValue !== null) {
        this.arrayDataDistributors = this.arrayDataDistributors.filter(x => x.userId === this.filterValue)
      }
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "OK",
        cancelButtonText: "No",
      });
    },
    showSection(value) {
      this.showUpload = (value === 'uploads')
      this.showDownload = (value === 'downloads')
    },
    validateNavbar() {
      if ((this.$store.state.buySale.information.haveChildren && !this.$store.state.user.subUser) ||
        (this.$store.state.buySale.information.haveChildren && this.$store.state.user.subUser && this.$checkSubUserPermission('downloads.uploads_files'))) {
        this.showUpload = true
      } else if ((!this.$store.state.user.subUser) || (this.$store.state.user.subUser && this.$checkSubUserPermission('downloads.downloads_files'))) {
        if (this.showUpload) {
          this.showUpload = false
        }
        this.showDownload = true
      }
    },
    async getTotalFiles() {
      const {data} = await get("general-files/get-total-files", null, true)
      this.totalFiles = (data <= 9)
    }
  }
});
